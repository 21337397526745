/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function checkMenu({ switchDataTab}) {
  const handleClickMenu = (e) => {
    e.preventDefault();
    // Get all children of the parent element, including the clicked element
    const children = Array.from(e.currentTarget.parentElement.children);

    // Add 'w-12' class to all children
    children.forEach((child) => {
      child.classList.add("w-12");
      const spanElement = child.querySelector("span");
      if (spanElement) {
        spanElement.classList.add("hidden");
      }
    });

    // Remove 'w-12' class from the clicked element
    e.currentTarget.classList.remove("w-12");
    e.currentTarget.querySelector("span").classList.remove("hidden");
    switchDataTab(e.currentTarget.querySelector("span").textContent.toLowerCase());
};
  return (
    <>
      <div className="flex flex-col text-end mt-16 justify-start items-end">
        <a
          onClick={handleClickMenu}
          href="#"
          className="bg-main-dark rounded-s-lg text-white p-2 flex flex-row items-center h-12"
        >
          <i className="fa-solid fa-circle-info fa-xl"></i>
          <span className="ms-3 font-bold">Info</span>
        </a>
        <a
          onClick={handleClickMenu}
          href="#"
          className="bg-main-dark rounded-s-lg text-white p-2 flex flex-row items-center h-12 w-12"
        >
          <i className="fa-solid fa-desktop fa-xl"></i>
          <span className="ms-3 hidden font-bold">Specs</span>
        </a>
        <a
          onClick={handleClickMenu}
          href="#"
          className="bg-main-dark rounded-s-lg text-white p-2 flex flex-row w-12 items-center h-12"
        >
          <i className="fa-solid fa-xl fa-vial-circle-check"></i>
          <span className="hidden ms-3 font-bold">Test</span>
        </a>
        <a
          onClick={handleClickMenu}
          href="#"
          className="bg-main-dark rounded-s-lg text-white p-2 flex flex-row w-12 items-center h-12"
        >
          <i className="fa-solid fa-xl fa-folder-open"></i>
          <span className="ms-3 hidden font-bold">Docs</span>
        </a>
      </div>
    </>
  );
}
