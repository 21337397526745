import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import Hr from "../../views/Hr";
import Cst from "../../views/Cst";
import ItCheck from "../../views/ItCheck";
import Settings from "../../views/Settings";
import Oops from "../page/oops";

const Sidebar = () => {
  const [activeModule, setActiveModule] = useState("hr");
  const rootRef = useRef(null);
  const [startX, setStartX] = useState(0);
  const [isHidden, setIsHidden] = useState(false);

  const updateSidebarVisibility = () => {
    const sidebar = document.getElementById("sidebar");
    if (isHidden) {
      sidebar.classList.add("hidden-sidebar");
      sidebar.classList.remove("visible-sidebar");
    } else {
      sidebar.classList.add("visible-sidebar");
      sidebar.classList.remove("hidden-sidebar");
    }
  };

  useEffect(() => {
    updateSidebarVisibility();
  }, [isHidden]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)"); // Tablet and smaller
    const handleMediaChange = (e) => {
      if (!e.matches) {
        setIsHidden(false);
      }
    };

    if (mediaQuery.matches) {
      document.addEventListener("touchstart", handleTouchStart);
      document.addEventListener("touchend", handleTouchEnd);
    }

    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      if (mediaQuery.matches) {
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchend", handleTouchEnd);
      }
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [startX]);

  useEffect(() => {
    const userImage = document.querySelector(".userimage");
    const handleUserImageClick = () => {
      setIsHidden(false);
    };

    if (userImage) {
      userImage.addEventListener("click", handleUserImageClick);
    }

    return () => {
      if (userImage) {
        userImage.removeEventListener("click", handleUserImageClick);
      }
    };
  }, []);

  useEffect(() => {
    const loadContent = async (module) => {
      try {
        let Component;
        switch (module) {
          case "hr":
            Component = Hr;
            break;
          case "cst":
            Component = Cst;
            break;
          case "itCheck":
            Component = ItCheck;
            break;
          case "settings":
            Component = Settings;
            break;
          default:
            Component = Oops;
        }
        // Render the component into the div with id "content"
        const contentDiv = document.getElementById("content");
        if (contentDiv) {
          if (!rootRef.current) {
            rootRef.current = createRoot(contentDiv);
          }
          rootRef.current.render(<Component />);
          console.log("Component loaded: ", Component);
        }
      } catch (error) {
        const contentDiv = document.getElementById("content");
        if (contentDiv) {
          contentDiv.innerHTML = "Error loading content";
        }
      }
    };

    loadContent(activeModule);
  }, [activeModule]);

  const handleModuleClick = (module) => {
    console.log("Module clicked:", module);
    if (window.innerWidth <= 768) {
      setIsHidden(true);
    }
    setActiveModule(module);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    if (startX - endX > 20) {
      console.log("Swipe left");
      if (window.innerWidth <= 768) {
        setIsHidden(true);
      }
    } else if (endX - startX > 20) {
      console.log("Swipe right");
      setIsHidden(false);
    }
  };

  return (
    <aside
      className="w-40 h-full flex flex-col justify-between top-0 left-0 shadow-xl bg-white z-50 fixed lg:relative"
      id="sidebar"
    >
      <div className="flex flex-col items-start w-full h-full">
        <div
          className={`${
            activeModule === "hr"
              ? "text-amber-400 bg-gray-50"
              : "text-main-dark bg-white"
          } flex flex-row w-full items-center text-main-dark p-2 hover:text-amber-400 cursor-pointer moduleLink `}
          data-group="hr"
          key="hr"
          onClick={() => handleModuleClick("hr")}
        >
          <i className="fa-solid fa-users-between-lines fa-2x mr-1"></i>
          <div>RRHH</div>
        </div>

        <div
          className={`${
            activeModule === "cit"
              ? "text-amber-400 bg-gray-50"
              : "text-main-dark bg-white"
          } flex flex-row w-full items-center text-main-dark p-2 hover:text-amber-400 cursor-pointer moduleLink `}
          data-group="cit"
          key="cit"
          onClick={() => handleModuleClick("cit")}
        >
          <i className="fa-solid fa-bug fa-2x mr-3"></i>
          <div>CIT</div>
        </div>

        <div
          className={`${
            activeModule === "cst"
              ? "text-amber-400 bg-gray-50"
              : "text-main-dark bg-white"
          } flex flex-row w-full items-center text-main-dark p-2 hover:text-amber-400 cursor-pointer moduleLink `}
          data-group="cst"
          key="cst"
          onClick={() => handleModuleClick("cst")}
        >
          <i className="fa-solid fa-person-chalkboard fa-2x mr-1"></i>
          <div>Coachings</div>
        </div>

        <div
          className={`${
            activeModule === "itCheck"
              ? "text-amber-400 bg-gray-50"
              : "text-main-dark bg-white"
          } flex flex-row w-full items-center text-main-dark p-2 hover:text-amber-400 cursor-pointer moduleLink `}
          data-group="itCheck"
          key="itCheck"
          onClick={() => handleModuleClick("itCheck")}
        >
          <i className="fa-solid fa-list-check fa-2x mr-3"></i>
          <div>IT Checks</div>
        </div>

        <div
          className={`${
            activeModule === "teams"
              ? "text-amber-400 bg-gray-50"
              : "text-main-dark bg-white"
          } flex flex-row w-full items-center text-main-dark p-2 hover:text-amber-400 cursor-pointer moduleLink `}
          data-group="teams"
          key="teams"
          onClick={() => handleModuleClick("teams")}
        >
          <i className="fa-solid fa-user-group fa-2x mr-1"></i>
          <div>Equipos</div>
        </div>
      </div>
      <div className="flex flex-col items-center w-full">
        <div
          className={`${
            activeModule === "settings"
              ? "bg-main-light text-white"
              : "text-main-light bg-white"
          } flex flex-row w-full items-center text-main-light p-2 hover:bg-main-light hover:text-white cursor-pointer moduleLink `}
          data-group="settings"
          key="settings"
          onClick={() => handleModuleClick("settings")}
        >
          <i className="fa-solid fa-gears fa-2x mr-2"></i>
          <div>Configuración</div>
        </div>
        <div
          className={`${
            activeModule === "logout"
              ? "bg-main-light text-white"
              : "text-main-light bg-white"
          } flex flex-row w-full items-center text-main-light p-2 hover:bg-main-light hover:text-white cursor-pointer moduleLink `}
          data-group="logout"
          key="logout"
          onClick={() => handleModuleClick("logout")}
        >
          <i className="fa-solid fa-sign-out fa-2x mr-3"></i>
          <div>Cerrar Sesión</div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
