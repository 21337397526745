import React, { useState, useEffect } from "react";
import Latest from "../components/itChecks/latest.jsx";
import Next from "../components/itChecks/next.jsx";
import Check from "../components/itChecks/check.jsx";
import Oops from "../components/page/oops.jsx";

const ItCheck = () => {
  const [activeGroup, setActiveGroup] = useState("next");
  const [content, setContent] = useState(<Next />);

  useEffect(() => {
    document.getElementById("module").textContent = "IT Checks";
    document.getElementById("breadcrumbLink").style.display = "none";
    document.getElementById("subModule").textContent = "";

    const loadContent = (group) => {
      // Simulate loading content
      switch (group) {
        case "next":
          setContent(<Next idUser={1}/>);
          break;
        case "past":
          setContent(<Latest idUser={1}/>);
          break;
        case "check":
          setContent(<Check idUser={1} idAgent={1}/>);
          break;
        default:
          setContent(<Oops />);
      }
    };

    loadContent(activeGroup);
  }, [activeGroup]);

  const handleGroupClick = (group) => {
    setActiveGroup(group);
  };

  return (
    <div className="h-full w-full flex flex-col ">
      <div id="divContent" className="flex h-full">{content}</div>
      <div className="flex flex-row h-12 w-full mt-auto justify-evenly">
        {["next", "past", "check", "search"/*, "profile"*/].map((group) => (
          <div
            key={group}
            className={`hrIcon flex flex-col w-full items-center p-2 cursor-pointer justify-center ${
              activeGroup === group
                ? "bg-white text-main-dark"
                : "bg-main-dark text-white"
            }`}
            data-group={group}
            onClick={() => handleGroupClick(group)}
          >
            <i className={`fa-solid fa-${getIconClass(group)}`}></i>
            <p className="text-xs">{getGroupName(group)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const getIconClass = (group) => {
  switch (group) {
    case "next":
      return "square-caret-right";
    case "past":
      return "clock-rotate-left";
    case "check":
      return "check-to-slot";
    case "search":
      return "magnifying-glass";
    /*case "profile":
      return "circle-user";*/
    default:
      return "";
  }
};

const getGroupName = (group) => {
  switch (group) {
    case "next":
      return "Próximos";
    case "past":
      return "Historial";
    case "check":
      return "Check";
    case "search":
      return "Buscar";
    /*case "profile":
      return "Perfil";*/
    default:
      return "";
  }
};

export default ItCheck;
