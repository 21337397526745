import React from 'react';

export default function latest({ idUser }) {
  document.getElementById('subModule').textContent = 'Recientes';
  document.getElementById("breadcrumbLink").style.display = "block";
  const data = [
    { date: '2024-08-01', name: 'Sarah Estrada', client: 'Cloudbreak', color: 'text-sky-900', type: 'Individual' },
    { date: '2024-08-02', name: 'Madsuze Lucien', client: 'FC BPO', color: 'text-green-500', type: 'Group' },
    { date: '2024-08-03', name: 'Kenia Santos', client: 'Propio', color: 'text-teal-400', type: 'Individual' },
    { date: '2024-08-05', name: 'Yanira Rodriguez', client: 'FC BPO', color: 'text-green-500', type: 'Group' },
    { date: '2024-08-08', name: 'Yanira Rodriguez', client: 'FC BPO', color: 'text-green-500', type: 'Group' },
  ];

  return (
    <div className="flex flex-col items-center space-y-5 w-full">
      <h4 className="text-2xl mt-2">Últimos IT Checks</h4>
      <table className="">
        <thead className="bg-main-dark text-white">
          <tr>
            <th className='rounded-tl'>Fecha</th>
            <th>Nombre</th>
            <th>Cliente</th>
            <th className='rounded-tr'>Tipo</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={`cursor-pointer text-xs font-bold ${item.color} md:text-lg`}>
              <td>{item.date}</td>
              <td>{item.name}</td>
              <td className="text-end">{item.client}</td>
              <td className="text-end">{item.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};