import React, { useState, useEffect } from "react";
import Home from "../components/hr/home";
import Oops from "../components/page/oops";

const Hr = () => {
  const [activeGroup, setActiveGroup] = useState("home");
  const [content, setContent] = useState(<Home />);

  useEffect(() => {
    document.getElementById("module").textContent = "Recursos Humanos";
    document.getElementById("breadcrumbLink").style.display = "none";

    const loadContent = (group) => {
      // Simulate loading content
      switch (group) {
        case "home":
          setContent(<Home />);
          break;
        default:
          setContent(<Oops />);
      }
    };

    loadContent(activeGroup);
  }, [activeGroup]);

  const handleGroupClick = (group) => {
    setActiveGroup(group);
  };

  return (
    <div className="h-full w-full flex flex-col">
      <div id="divContent">{content}</div>
      <div className="flex flex-row h-12 w-full mt-auto justify-evenly">
        {["home", "inbox", "people", "files", "profile"].map((group) => (
          <div
            key={group}
            className={`hrIcon flex flex-col w-full items-center p-2 cursor-pointer justify-center ${
              activeGroup === group
                ? "bg-white text-main-dark"
                : "bg-main-dark text-white"
            }`}
            data-group={group}
            onClick={() => handleGroupClick(group)}
          >
            <i className={`fa-solid fa-${getIconClass(group)}`}></i>
            <p className="text-xs">{getGroupName(group)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const getIconClass = (group) => {
  switch (group) {
    case "home":
      return "house-chimney";
    case "inbox":
      return "inbox";
    case "people":
      return "user-group";
    case "files":
      return "folder-open";
    case "profile":
      return "circle-user";
    default:
      return "";
  }
};

const getGroupName = (group) => {
  switch (group) {
    case "home":
      return "Inicio";
    case "inbox":
      return "Buzón";
    case "people":
      return "Personal";
    case "files":
      return "Archivos";
    case "profile":
      return "Perfil";
    default:
      return "";
  }
};

export default Hr;
